.row {
  display: flex;
}

.nested {
  flex-grow: 1;
}

.remove {
  flex-basis: auto;
  margin-right: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
