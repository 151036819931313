@import "../variables";

#toc-wrapper {
  float: right;
  margin-left: 2rem;
  margin-right: 1rem;
  margin-bottom: 1rem;

  border: 1px solid $panel-border-color;
  border-radius: 0.25rem;
  padding: 0.5rem;
  background-color: $panel-background-color;
}