.titleEditContainer {
  display: flex;
  align-items: flex-end;
}

.postPreview {
  font-size: smaller;
  border: 1px dotted cyan;
  padding-top: 8px;
}
