.inlineLink {
  display: inline;

  white-space: nowrap;
}

.inlineLinkIcon {
}

.inlineLinkLink {
  white-space: normal;
}

.il_quest {
  color: #CC8844;
}

.il_discovery {
  color: #BBAA22;
}

.il_skill {
  color: #44AAAA;
}

.il_job {
  color: #ffcc55;
}

.il_item {
  color: #CC99AA;
  font-size: 90%;
}

.il_equipment {
  color: #55AA44;
}

.il_zone {
  color: #55ddff;
}

.il_trade-good {
  color: #dd8844;
}

.il_consumable {
  color: #3bd63e;
}

.il_ship {
  color: #c2f1dc;
}

.il_wiki {
  color: #aaa166;
}