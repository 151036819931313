$main-text-color: #FDFDFD;
$link-color: #88bbee;
$weak-text-color: #b4b4b4;

$main-background-color: #002340;

$panel-background-color: #004680;
$panel-border-color: #5599CC;
$panel-subheader-background-color: #6D97b7;
$panel-subheader-text-color: #111111;

$result-separator-color: #4466AA;

$intermediate-background-color: #003460;
$highlight-background-color: #4e68aa;
$enabled-input-background-color: #002850;