.centerBlock {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.expSkill {
  color: #dd4477;
}

.favSkill,
.refinedSkill {
  color: gold;
}

.skillRequirementBox {
  width: 52px;
  display: inline-block;
}

.fullWidth {
  width: 100%;
}