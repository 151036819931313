@import "../variables";

.infoPanelSubheader {
  margin-left: -0.25em;
  margin-right: -0.25em;
  background-color: $panel-subheader-background-color;
  color: $panel-subheader-text-color;
  font-family: 'Open Sans', sans-serif;
  font-weight: bolder;
  padding-left: 0.25em;
  border-radius: 0.25em;
}

.infoPanelSubheaderTitle {
  margin-left: 0.5em;
}

.infoPanelSubheaderRow {
  &:not(:first-child) {
    margin-top: 1rem !important; //.mt-3
  }
}

.infoPanelContentRow {
  margin-top: 0.5rem !important; //.mt-2
}