.wikiEditor {
  max-height: 79vh!important;
  overflow-y: auto!important;
}

.wikiPreview {
  border: 1px dotted cyan;
  padding: 8px;

  height: 90vh;
  overflow-y: auto;
}