@import "../styles/common.module";

.posterDisplayName {
  color: cyan;
}

.postDetails {
  margin-top: -0.5em;
  margin-left: 1em;
  font-size: x-small;
}

.postContent {
  margin-top: 0.5em;
  font-size: smaller;
}
