@import '../variables';

.infoPanel {
  background-color: $panel-background-color;
  border: 2px solid $panel-border-color;
  border-radius: 0.5rem;
}

.infoPanelTitleCol {
  display: flex;
  align-items: center;
}

.infoPanelTitle {
  margin: 0.25rem 0;

  h1 {
    display: inline;
    font-size: 1.5rem;
  }
}

.editButtonContainer {
  margin-left: 1rem;
  flex-grow: 1;
}

.infoPanelHeaderImage {
  justify-self: flex-end;
}

.part {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  &:not(:last-child) {
    border-bottom: 2px solid $panel-border-color;
  }
}