@import "../variables";

.selected {
  display: flex;
  align-items: center;
}

.remove {
  margin-left: 0.25rem;
}

.input {
  input {
    color: $main-text-color !important;
  }
}

.option {
  white-space: nowrap;
}