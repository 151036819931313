@import url('https://fonts.googleapis.com/css?family=Roboto|Open+Sans&display=swap');

@import 'variables';

body {
  margin: 0;
  font-family: 'Roboto', 'Open Sans', 'Segoe UI', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Consolas, 'Courier New', monospace;
}

html, body {
  height: 100%;

  color: $main-text-color;
  background: $main-background-color;
}

a, a:hover {
  color: $link-color;
}

.form-control {
  background-color: $enabled-input-background-color;
  color: $main-text-color;
  border-color: $panel-border-color;
}

.form-control:focus {
  background-color: $panel-background-color;
  color: $main-text-color;
}

.form-control:disabled {
  background-color: $intermediate-background-color;
  color: $weak-text-color;
}

.form-control::placeholder {
  color: $weak-text-color;
}

.input-group-text {
  background-color: $intermediate-background-color;
  color: $weak-text-color;
  border-color: $panel-border-color;
}