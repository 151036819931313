@import "../../variables";
@import "../../styles/common.module";

.tooltipContainer {
  padding: 0.3rem;
}

.tooltipMain {
  background-color: $panel-background-color;
  border: 1px solid $panel-border-color;
}

.tooltipTitleIcon {
  float: right;
}

.tooltipRow {
  margin-left: -0.3rem;
  margin-right: -0.3rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;

  &:not(:last-child) {
    padding-bottom: 0.1rem;
    border-bottom: 1px dotted $panel-border-color;
  }

  &:not(:first-child) {
    padding-top: 0.1rem;
  }
}

.tooltipTitle {
  @extend .tooltipRow;

  font-weight: bolder;
}
