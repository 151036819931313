.rateBarContainer {
    display: flex;
}

.rateBar {
    display: flex;

    width: 100%;
    height: 1rem;
    background-color: #112244;

    border-radius: 0.25rem;
}

.rateValueBar {
    display: flex;
    flex-direction: column;
    justify-content: center;

    background-color: #3bd63e;

    border-radius: inherit;
}

.rateValue {
    width: 2rem;
    text-align: right;
    font-size: 75%;
}