@import "../../styles/common.module";

.refinedSkillIconContainer {
  display: flex;
}

.refinedSkillIcon {
  @extend .refinedSkill;

  align-self: center;
}

.skillDescription {
  font-size: smaller;
}
