.locationTopContainer {
  display: flex;
  align-items: center;
}

.multipleContainer {
  margin-left: 0.5rem!important;
  margin-top: 0.1rem!important;
}

.resultsInformation {
  margin-top: 8px;
  margin-bottom: 8px;
}
