@import "styles/common.module";

.sidebar {
  min-width: 100px;

  font-size: xx-large;

  @media (min-width: 768px) {
    font-size: x-large;
  }
}

.appContainer {
  margin-top: 16px;
}

.mainContent {
  margin-top: 16px;
}