.container {
    position: fixed;
    z-index: 2000!important;

    bottom: 1rem;
    width: 100%;
}

.banner {
    display: flex;

    background-color: #4d5d32;
    padding: 0.5rem;

    border: 1px solid #a1b56e;
    border-radius: 4px;
}

.notice {
    flex-grow: 1;
    font-size: small;
}

.button {
    margin-left: 1rem;
}