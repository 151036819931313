@import "../variables";

.option {
  display: flex;
  align-items: center;

  width: 100%;

  font-size: small;

  padding: 0.1rem!important;
}

.optionIcon {
  flex-basis: 2rem;

  text-align: center;
}

.optionName {
  flex-basis: 100%;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.optionType {
  justify-self: flex-end;
  flex-basis: 130px;
  margin-left: 1rem;
  margin-right: 0.2rem;

  color: $weak-text-color;
  font-weight: bolder;
  font-size: x-small;
  text-align: right;
}
