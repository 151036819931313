.subclassBtn {
  width: 100px;
}

.jobLink {
  vertical-align: sub;
}

.levelReq {
  width: 3em;
  display: inline-block;
  vertical-align: sub;
}

.skillsBox {
  display: flex;
}

.expertBox {
  margin-left: 1rem;
  flex-basis: 3rem;
  display: inline-block;
}

.favouredBox {
  flex-basis: auto;
}