.underConstructionBar {
  background-color: #446622;
  border: 1px solid #99aa66;
  border-radius: 4px;
}

.underConstructionIconContainer {
  text-align: center;
  margin: auto;

  font-size: larger;
  color: #eeff33;
}

.underConstructionTitle {
  text-align: center;
}

.underConstructionNotice {
  font-size: smaller;
  text-align: center;
}