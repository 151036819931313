.recipeDescription {
  font-size: smaller;
}

.recipeBox {
  display: flex;
  justify-content: center;

  .ingredientsBox {
    display: flex;
    flex: 1;

    justify-content: flex-end;

    @media (max-width: 767px) {
      justify-content: center;
    }
  }

  .arrowBoxRight {
    margin-top: 1em;
    width: 2em;
    text-align: center;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .arrowBoxDown {
    height: 2em;
    text-align: center;

    @media (min-width: 768px) {
      display: none;
    }
  }

  .productBox {
    display: flex;
    flex: 1;

    @media (max-width: 767px) {
      justify-content: center;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.itemBox {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100px;
}

.itemIcon {
  position: relative;

  width: 48px;
  height: 48px;

  .count {
    position: absolute;
    right: 0.4em;
    bottom: 0;

    text-shadow: 0 0 3px black, 0 0 3px black, 0 0 3px black, 0 0 3px black;
  }
}

.itemName {
  font-size: smaller;
  text-align: center;
}

.diceBox {
  margin-top: auto;
}

.dice {
  font-size: smaller;
}
