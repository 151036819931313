@import "../variables";

.scrollTopTopButton {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  width: 3rem;
  height: 3rem;

  border-radius: 1rem;

  color: $main-text-color;
  background-color: #335577;
  transition: background-color 300ms linear;

  &:hover {
    background-color: #557799;
    transition: background-color 300ms linear;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1000!important;
}

.visible {
  transition: bottom 100ms linear;
  bottom: 2rem;
}

.invisible {
  transition: bottom 100ms linear;
  bottom: -4rem;
}

.arrowContainer {
}