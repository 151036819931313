@import "../variables";

.selectedSkill {
  display: flex;
  align-items: center;

  cursor: default;

  border: 1px solid $panel-border-color;
  border-radius: 4px;
  background-color: #3355aa;
  font-size: smaller;

  padding: 1px;
  margin-bottom: 0.15rem;

  &:not(:first-child) {
    margin-left: 0.25rem;
  }
}
